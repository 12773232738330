import React, {useEffect} from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import PushBanner from '../../atoms/PushBanner/PushBanner';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {manageGTMPageName} from './../../utils/GTMDataLayerFuncs';
import { getBreadcrumbData } from '../../utils/breadcrumbJson';
import './HistoricalDataContainer.scss';

const HistoricalDataContainer = props => {
  const {t} = useTranslation();
  const breadcrumbNav = props && props.breadcrumb;

  const getLinks = () => {
    const links = [
      {label: `${t('futures')}`, link: '/granos/series-historicas-futuros'},
      {label: `${t('physical')}`, link: '/granos/series-historicas-fisico'},
      {label: `${t('board')}`, link: '/granos/series-historicas-pizarra'},
      {label: `${t('currencies')}`, link: '/granos/series-historicas-monedas'},
      {label: `${t('economic_indicators')}`, link: '/granos/series-historicas-indicadores-economicos'}
    ];
    return <ul>
      {links.map(item =>
        <li key={item.label} className={isActive(item.label) ? 'active' : ''}><Link to={item.link}>{item.label}</Link></li>
      )}
    </ul>;
  };

  const isActive = label => {
    var key = breadcrumbNav && Object.keys(JSON.parse(breadcrumbNav)[1])[0];

    return key && (label === key ? 'active' : '');
  };

  useEffect(() => {
    window && manageGTMPageName('Indicadores económicos');
  }, []);
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="historical-data">
        <div className="historical-data__page container px-0">
          <PushBanner dfpPrefix="precios" />
          <section id="main-section" className="historical-data__page-content d-flex flex-wrap mt-20">
            <div className="fluid-container col-lg-9 px-0">
              <section className="breadcrumb-wrapper p-0 mb-10">
                {<Breadcrumb breadcrumbLinks={getBreadcrumbData(breadcrumbNav, true)} />}
              </section>
              <section className="pages-togler">
                {getLinks()}
              </section>
              <section className="container">
                {props.children}
              </section>
            </div>
            <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
              <div className="make-me-sticky">
                <AdSlot adUnit={'precios_followme'} sizes={[[260, 600]]} />
              </div>
            </div>
          </section>
        </div>
        <AdhesionBanner adUnit='precios_adhesion' sizes={[[320, 80]]} />
        <PopUpBanner adUnit='precios_fullscreen' sizes={[[800, 600], [320, 480]]} />
      </div>
    </DFPSlotsProvider>
  );
};

export default HistoricalDataContainer;
