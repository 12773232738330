import React, { useState, useEffect, useContext } from "react";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AdhesionBanner from "../../../atoms/AdhesionBanner/AdhesionBanner";
import PopUpBanner from "../../../atoms/PopUpBanner/PopUpBanner";
import getData from "../../../services/GetData";
import PaginationAgrofy from "../../../atoms/PaginationAgrofy/PaginationAgrofy";
import SectionTemplate from "../../../templates/sectionTemplate/SectionTemplate";
import { AgroPushNotification } from "../../../components/AgroPushNotification/AgroPushNotification";
import withSSR from "../../../withSSR";
import {
  gtmInitialize,
  manageGTMPageName,
  manageGTMUserStatus
} from "./../../../utils/GTMDataLayerFuncs";
import UserContext from "../../../atoms/UserContext/UserContext";

const Corteva = (props) => {
  const { t } = useTranslation();
  const serverData = props && props.serverData ? props.serverData : false;
  const [updatedData, setUpdatedData] = useState();
  const [seoTitle, setSeoTitle] = useState();
  const [seoText, setSeoText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const tagName =
    (serverData &&
      serverData.title_section &&
      serverData.title_section.replace(/^\w/, (c) => c.toUpperCase())) ||
    "";
  const amount = serverData && serverData.pages;

  const selfUrl = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;

  const dfpPrefix = "agrofynews_especificos/corteva";
  const dfpNetworkId1 = "21735159925";

  const { mkpUserData, userLoading } = useContext(UserContext);

  const templateBanners = [
    {
      adUnit: "corteva_middle_1",
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "corteva_middle_2",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "corteva_middle_3",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "corteva_filmstrip",
      sizes: [[300, 600]],
    },
  ];

  const fetchData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_NOTI_API ||
      window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true);
    return getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/corteva-partner&page=${
        currentPage - 1
      }&limit=15`,
    }).then((result) => {
      setUpdatedData(result.data);
      /* setSeoTitle(result && result.data && result.data.title_section);
      setSeoText(result && result.data && result.data.seoText); */
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData) {
      const section = document.getElementById("section-template");
      fetchData();
      if (section) {
        document.getElementById("section-template").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName(`Home ${serverData.title_section}`);
    }
  }, [serverData]);

  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth >= 600) {
        setImageSrc('https://news.agrofystatic.com/partners/corteva_1170x230.jpg?d=1170x230');
      } else {
        setImageSrc('https://news.agrofystatic.com/partners/corteva_1170x230.jpg?d=420x150');
      }
    };

    updateImage();
    window.addEventListener('resize', updateImage);
    return () => window.removeEventListener('resize', updateImage);
  }, []);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
      <div className="container px-0">
        <Helmet>
          <title>{`Corteva | Agrofy News`}</title>
          <meta name="robots" content="index,follow" />
          <link
            rel="canonical"
            href="https://news.agrofy.com.ar/partners/corteva"
          />
          <meta
            name="description"
            content="Te presentamos la sección oficial de Corteva en Agrofy News. Acá vas a encontrar contenidos exclusivos, lanzamientos y todas las novedades de la empresa. ¡Bienvenido!"
          />
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="mt-30 dfp-banner container">
          <img 
            src={imageSrc} 
            alt="Corteva logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />  
        </section>
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="container">
              {/* <h1>Corteva{/* {seoTitle} }</h1> */}
              <p className="mt-10">Te presentamos la sección oficial de Corteva en Agrofy News. Acá vas a encontrar contenidos exclusivos, lanzamientos y todas las novedades de la empresa. ¡Bienvenido!{/* seoText */}</p>
              <SectionTemplate
                hasVideo={true}
                isExpo={true}
                videoCategory={selfUrl.includes("develop") ? "32257" : "32265"}
                data={updatedData || serverData}
                classes="small-spacing-top"
                bannersData={templateBanners}
                loading={isLoading}
                carouselKeyword="Corteva"
                showNewslettersForm={false}
              />
              <div className="mb-36">
                {currentPage && amount && (
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                )}
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={dfpPrefix + "_followme"} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
        <AdhesionBanner 
          adUnit={dfpPrefix + "_adhesion"}
          sizes={[[320, 80]]}
          specialNetworkID={dfpNetworkId1}
          
        />
        <PopUpBanner
          adUnit={dfpPrefix + "_fullscreen"}
          sizes={[
            [800, 600],
            [320, 480],
          ]}
          specialNetworkID={dfpNetworkId1}
        />
      </div>
    </DFPSlotsProvider>
  );
};

Corteva.getInitialProps = async ({ match, req, res }) => {
  const url =
    process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  try {
    const { data } = await getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/corteva-partner&page=0&limit=15`,
    });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Corteva);
