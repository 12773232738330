import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdSlot } from 'react-dfp';

const PushBanner = ({dfpPrefix}) => {
  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data && event.data.msg === "adcase" && event.data.format === "push") {
        const { action, transition } = event.data.params;

        const bannerElement = document.getElementById('top-banner');
        if (!bannerElement) return;
        
        if (action === "expand") {
          bannerElement.style.height = "250px";  
        } 
        setTimeout(() => {          
          if (action === "collapse") {
            bannerElement.style.height = "90px";  
          }
        }, 400);     
      }
    });
  }, []);

  return (
    <section className="dfp-banner stripped container top-banner" id="top-banner">
      <AdSlot
        adUnit={dfpPrefix + '_header'}
        sizes={[[970, 90], [970, 250], [320, 80]]}     
      />
    </section>
  );
};

PushBanner.propTypes = {
  dfpPrefix: PropTypes.string
};

export default PushBanner;
