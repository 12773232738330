import React, { useEffect, useState, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
// import Observer from '@researchgate/react-intersection-observer';
import { Container, Row, AgroSpinner } from 'agrofy-ui-components';
import styled, { css } from 'styled-components';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import CategoryVideosTemplate from '../../templates/CategoryVideosTemplate/CategoryVideosTemplate';
import { CarouselVideos } from '../../molecules/CarouselVideos/CarouselVideos';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import { useStore } from '../../hooks/store'
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import PushBanner from '../../atoms/PushBanner/PushBanner';
import Logo from '../../images/icons/agrofytvLogo.svg';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { splitUrl, strToLowerCase } from './../../utils/stringUtils';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import { structuredVideoData } from '../../utils/StructuredVideoData';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import { useTranslation } from 'react-i18next';
import UserContext from '../../atoms/UserContext/UserContext';

const AgrofyTv = (props) => {
  const { t } = useTranslation();
  const idVideo = props && props.match && props.match.params && props.match.params.idVideo;
  const serverData = props && props.serverData;
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const [loadedComponents, setLoadedComponents] = useState({});
  const [videosData, setVideosData] = useState({ 1: null, 2: null, 3: null, 4: null });
  const [position, setPosition] = useState(1);
  const [isMobile, setIsMobile] = useState(true);
  const [state, dispatch] = useStore();
  const [categories, setCategories] = useState(null);
  const [banner1Loaded, setBanner1Loaded] = useState('no-loaded-banner');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner');
  const [middle3Loaded, setMiddle3Loaded] = useState('no-loaded-banner');
  const [categories2, setCategories2] = useState(null);
  const [lightVideo, setLightVideo] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  const url = process.env.RAZZLE_ENDPOINT_SELF || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_SELF;

  const pathName = props && props.location && props.location.pathname;
  const playingVideo = state && state.videoData;
  const templateBanners = [
    {
      adUnit: 'agrofytv_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_middle_2',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_middle_3',
      sizes: [[728, 90], [300, 250], [320, 50]]
    }
  ];

  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  const fetchDataAllVideos = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/AgrofyTV/GetAll` })
      .then(result => {
        setCategories2(result.data)
        setIsLoading(false)
      })
  };


  const fetchPlaylistsData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    return getData({ url: `${url}/api/AgrofyTV/GetPlaylistsData` })
      .then(result => {
        setCategories(result.data)
      })
  }

  const fetchFeaturedVideo = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    return getData({ url: `${url}${idVideo ? `/api/AgrofyTV/GetVideoByID?videoID=${idVideo}` : "/api/AgrofyTV/GetHighlightedVideo"}` })
      .then(result => {
        dispatch('SET_CURRENT_VIDEO_DATA', result.data)
        setFeaturedVideo(result.data)
      })
  }

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };
  }, []);

  useEffect(() => {
    fetchDataAllVideos();
    fetchPlaylistsData();
    fetchFeaturedVideo();
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)
    setIsMobile(isMobileDevice)
    window.scrollTo(0, 0);
  }, [position]);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName('Home Agrofy Tv');
    };
  }, [serverData]);

  // const categoriesFiltered = isMobile ? categories : categoriesWithoutMostViewed;
  // const categoriesFiltered = categories;
  //const amountOfCategories = isMobile ? categories.length + 1 : categoriesWithoutMostViewed.length + 1;
  const amountOfCategories = categories && categories.length + 1;

  const addView = (componentName, idComponent) => {
    let loadedComponent = {}
    loadedComponent[componentName] = <CategoryVideosTemplate data={videosData[idComponent]} isHome withModal={isMobile} onChildClick={handleChildClick} />
    setLoadedComponents({ ...loadedComponents, ...loadedComponent })
  }

  const handleChange = (event, unobserve) => {
    const componentName = event.target.dataset.name;
    const idComponent = event.target.dataset.id;
    if (event.isIntersecting) {
      position <= amountOfCategories && setPosition(position + 1)
      addView(componentName, idComponent)
    }
  }

  const options = {
    onChange: handleChange,
    rootMargin: '50px 0px 50px 0px'
  };

  const handleChildClick = (data) => {
    setLightVideo(false)
    dispatch('SET_CURRENT_VIDEO_DATA', data)
    window.scrollTo(0, 0);
  }
  // const firstCategories = categories && categoriesFiltered.slice(0, 2);
  // const restOfCategories = categories && categoriesFiltered.slice(2);

  const firstCategories2 = categories2 && categories2.slice(0, 2);
  const restOfCategories2 = categories2 && categories2.slice(2);

  const breadcrumbArr = [
    { level: 'Agrofy News', urlLevel: url },
    { level: 'AgrofyTV', urlLevel: url + '/' + splitUrl(pathName, 1) },
    playingVideo && idVideo && { level: playingVideo.title, urlLevel: url + '/' + pathName.replace(/^\/|\/$/g, '') }
  ];

  const breadcrumb = [
    {
      title: "AgrofyTV",
      link: '/tv'
    },
    playingVideo && idVideo &&
    {
      title: playingVideo.title,
      link: playingVideo.urlSlug
    }
  ];

  const titleText = isBrasil
  ? 'Fique por dentro das melhores entrevistas e vídeos sobre o agro'
  : 'Informate con las mejores charlas y videos del agro';

  const descriptionText = isBrasil
  ? 'Veja as melhores entrevistas e vídeos sobre a agroindústria. Fique por dentro das novidades, feiras, clima e muito mais com a gente'
  : 'Encontrá las mejores charlas y videos de la agroindustria. Enterate de las novedades en actualidad, ferias, clima y demás con nosotros en AgrofyTV';

  const seoTitle = `AgrofyTV | ${pathName === "/tv" ? titleText : idVideo ? playingVideo && playingVideo.title ? playingVideo.title : 'a' : 'a'}`;
  const seoDesc = `AgrofyTV | ${pathName === "/tv" ? descriptionText : idVideo ? playingVideo && playingVideo.description ? playingVideo.description : 'a' : 'a'}`;
  const seoUrl = `${url}${pathName}`;

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <React.Fragment>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="robots" content="index,follow" />
          <meta name="description" content={seoDesc} data-rh="true"/>
          <link rel="canonical" href={strToLowerCase(seoUrl)} />
          <meta property="og:url" content={seoUrl} />
          <meta property="og:title" content={seoTitle} />
          <meta property="og:image" content={''} />
          <meta property="og:description" content={seoDesc} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content={isBrasil ? '' : '@AgrofyNews'} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDesc} />
          <script type="application/ld+json">{buildBreadcrumbJson(breadcrumbArr)}</script>
          <script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          <script type="application/ld+json">{idVideo && playingVideo && structuredVideoData(playingVideo, false)}</script>
        </Helmet>
        <AgroPushNotification location="Section" />
        <PushBanner dfpPrefix="agrofytv" />
        <section className="breadcrumb-wrapper p-0 mt-20" style={{ maxWidth: "1200px", margin: "0 auto", marginTop: "20px" }}>
          <Breadcrumb breadcrumbLinks={breadcrumb} />
        </section>
        <LogoSection>
          <ContainerLogo><div><h1 className="mobile-desc">{t('videos_title_mob')}</h1><h1 className="desktop-desc">{t('videos_title')}</h1></div> <div><img src={Logo} /></div></ContainerLogo>
        </LogoSection>
        <MainVideoSection className="fluid-container">
          <CarouselVideos isCategoryPage={false} withCarousel selectedVideoFromCategories={featuredVideo} setLightVideo={lightVideo} />
        </MainVideoSection>
        <NewsContainer pt="md">
          <div className={`section-template__banner col-12 m-full-width ${banner1Loaded}`}>
            <div className="dfp-banner stripped">
              {!templateBanners ? <div className="banner b-300-250" /> :
                <AdSlot
                  adUnit={`${templateBanners[0].adUnit}`}
                  sizes={templateBanners[0].sizes}
                  onSlotRender={e => e.event.isEmpty ? '' : setBanner1Loaded('loaded-banner-mobile')}
                />
              }
            </div>
          </div>
          <Divider high="true" />
          {(categories2 && !isLoading) ? firstCategories2.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <CategoryVideosTemplate data={item} isHome={true} className={item.url.replace(/\//g, "-")} withModal={isMobile} onChildClick={handleChildClick} />
                {isMobile && <Divider />}
              </React.Fragment>
            )
          }) : <SpinnerContainer><AgroSpinner width='50px' height='50px' variant='primary' /> </SpinnerContainer>
          }
          {!isBrasil ? <div className={`mb-35 mt-35 container ${middle2Loaded}`}>
            <div className="dfp-banner stripped bm-negative-ml-15">
              <AdSlot
                adUnit={`${templateBanners[1].adUnit}`}
                sizes={templateBanners[1].sizes}
                onSlotRender={e => e.event.isEmpty ? '' : setMiddle2Loaded('loaded-banner')}
              />
            </div>
          </div> : null}
          {categories2 && restOfCategories2.map((item, index) => {
            return (
              <React.Fragment key={item.categoryName}>
                <CategoryVideosTemplate className={item.url.replace(/\//g, "-")} data={item} isHome withModal={isMobile} onChildClick={handleChildClick} />
                {isMobile && <Divider />}
              </React.Fragment>
            )
          })}
          {!isBrasil ? <div className={`mb-35 mt-35 container ${middle3Loaded}`}>
            <div className="dfp-banner stripped bm-negative-ml-15">
              <AdSlot
                adUnit={`${templateBanners[2].adUnit}`}
                sizes={templateBanners[2].sizes}
                onSlotRender={e => e.event.isEmpty ? '' : setMiddle3Loaded('loaded-banner')}
              />
            </div>
          </div> : null}
        </NewsContainer>
        <AdhesionBanner adUnit='agrofytv_adhesion' sizes={[[728, 90], [320, 80]]} />
        <PopUpBanner adUnit='agrofytv_fullscreen' sizes={[[800, 600], [320, 480]]} />
      </React.Fragment >
    </DFPSlotsProvider>
  )
}

AgrofyTv.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const idVideo = match.params.idVideo;
  try {
    const {
      data
    } = await getData({ url: `${url}${idVideo ? `/api/AgrofyTV/GetVideoByID?videoID=${idVideo}` : "/api/AgrofyTV/GetHighlightedVideo"}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(AgrofyTv);

export const LogoSection = styled.section`
    background: linear-gradient(115deg, #212529 62.9%, #495057 50%);
    display: flex;
    padding: 8px 0;
    margin-top: 20px;
    img {
        width: 100px;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}){
            display: block;
            padding: 16px 0;
            background: linear-gradient(115deg, #212529 76.9%, #495057 50%);
            img {
                width: 100%;
            }
        }
    `}
    @media only screen  and (min-width : 1824px) {
        background: linear-gradient(115deg, #212529 68.9%, #495057 50%);
    }
    @media only screen  and (max-width: 320px) {
        background: linear-gradient(115deg, #212529 61.9%, #495057 50%);
    }
`;
export const ContainerLogo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    h1, .title-video {
        color: #E9ECEF;
        font-size: 24px;
        line-height: 36px;
    }
    .mobile-desc {
        display: inline;
        font-size: 14px;
    }
    .desktop-desc {
        display: none;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-between; 
            padding: 0 12px;
            span {
                font-size: 24px;
            }
            .desktop-desc {
                display: inline;
            }
            .mobile-desc {
                display: none;
            }
        }
    `}
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.sm}) and (max-width: ${theme.breakpoint.md}) {
            padding: 0 16px;
            justify-content: space-between;
            span {
                display: block;
                font-size: 16px;
            }
        }
    `}
    @media only screen  and (max-width: 320px) {
        padding: 0 8px;
    }
`;

// const ObserverWrapper = styled.div`
//     min-height: ${p => p.isMobile ? "660px" : "330px"};
//     width: 100%;
//     &:last-of-type {
//         margin-bottom: 32px;
//     }
// `;

const Divider = styled.div`
    background: ${p => p.theme.colors.gray2};
    height: ${p => p.high ? "40px" : "24px"};
    display: block;
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            display: none;
        }
    `}
`;
const MainVideoSection = styled.section`
    background: #212529;
`;
const NewsRow = styled(Row)`
    &.row {
        margin: 20px 0;
    }
    .slick-slider {
        background: #212529;
        width: 100%;
        button .slick-arrow{
            color: red !important;
        }
    }
    .slick-slider.container {
        padding: 24px;
    }
`
export const NewsContainer = styled(Container)`
    padding-left: 0;  
    padding-right: 0;
    .m-full-width{
        margin-left: 0;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            padding-left: 12px;  
            padding-right: 12px;
        }
    `}
`;

const SpinnerContainer = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    min-height: 330px;
`;