import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { AgroLink } from "agrofy-ui-components";
import AgrofyPayLogo from '../Icons/AgrofyPayLogo';
import "../DailyDollar/DailyDollar.scss";

export const DailyPecuária = ({ quotesData, pecuariaShowSponsoredBy }) => {
  const { t } = useTranslation();
  const loader = (
    <div className="loader container pr-0" style={{ minHeight: "90px" }}>
      <div className="animated-background" />
    </div>
  );
  const style = (variationVal) =>
    variationVal > 0 ? "up arrow" : variationVal < 0 ? "down arrow" : "equal";
  const format = (val) => val && val.toString().replace(".", ",");
  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: "trackEvent",
        eventCategory: "Navigation",
        eventAction: "Agrofy News - Indicadores Home",
        eventLabel: "Bolsas y Monedas ",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <Link
      to={{
        pathname: "/cotacoes",
        state: "Sumário",
      }}
    >
      {!quotesData ? (
        loader
      ) : (
        <React.Fragment>
          <div className="component daily-news arrow dolar ">
            {pecuariaShowSponsoredBy && (
              <div className="sponsored-by">
                <span className="sponsored-by-text">{t('sponsored-by')}</span>
                <img alt="Registron" src="https://news.agrofystatic.com/sponsor-registron-2.png?d=67x14" width={67} height={14}/>
              </div>
            )}
            <div className={`title-container ${!pecuariaShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              <span className="title">{t("quote-second-title")}</span>
              <AgroLink size="lg">{t("view_more")}</AgroLink>
            </div>
            <div className={`daily-item ${!pecuariaShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              {quotesData[1].HomePriceTabs[0].HomePrices.map(
                (item, idx) => (
                  <ListContainer
                    key={idx}
                    className={` ${style(item.PercentualVariation)}`}
                  >
                    <label className="name">{item.ProductDescription}</label>

                    <div className="values-percents">
                      <label className="price">
                        R$ {item.Quote && format(item.Quote)}
                      </label>

                      <div>
                        <label className="percent">{`${format(
                          item.PercentualVariation
                        )} %`}</label>
                        <label className="indicator">
                          <label className="icon"></label>
                        </label>
                      </div>
                    </div>
                  </ListContainer>
                )
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </Link>
  );
};

export default DailyPecuária;

const ListContainer = styled.div`
 padding: 0 16px 4px;
`;