import React, { useState, useEffect, useContext } from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import getData from '../../services/GetData';
import { Helmet } from 'react-helmet-async';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import PushBanner from '../../atoms/PushBanner/PushBanner';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import withSSR from '../../withSSR';
import { Link } from 'react-router-dom';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import GranosTableResume from '../../atoms/Tables/GranosTableResume/GranosTableResume';
import GranosTable from '../../atoms/Tables/GranosTable/GranosTable';
import GranosTableBR from '../../atoms/Tables/GranosTableBR/GranosTableBR';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import {fetchGrainData} from '../../atoms/fetchGrainData/fetchGrainData';
import UserContext from '../../atoms/UserContext/UserContext';

function Granos(props) {
  const { t } = useTranslation();
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const loader = <div className="loader container px-0"><div className="animated-background" /></div>;
  const tableLoader = <div className="loader container p-0"><div className="animated-background" style={{ height: '300px' }} /></div>;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoadingResume, setIsLoadingResume] = useState(false);
  const [isLoadingGrain, setIsLoadingGrain] = useState(false);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const amount = (updatedData && updatedData.pages) || (serverData && serverData.pages);
  const [resumeTableData, setResumeTableData] = useState(null);
  const [tableData, setTableData] = useState();
  const [cepeaData, setCepeaData] = useState();

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';

  const { mkpUserData, userLoading } = useContext(UserContext);

  const selfUrl = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;
  const hrefLang = isBrasil ? "pt-br" : "es-ar";

  const routesAR = [
    {pageTitle: "Resúmen", pathName: "/granos", hrefLang : "/cotacoes"},
    {pageTitle: "Soja", pathName: "/granos/precio-soja", hrefLang: "/cotacoes/preco-soja"},
    {pageTitle: "Trigo",  pathName: "/granos/precio-trigo", hrefLang : "/cotacoes/preco-trigo"},
    {pageTitle: "Maíz",  pathName: "/granos/precio-maiz", hrefLang : "/cotacoes/preco-milho"},
    {pageTitle: "Girasol",  pathName: "/granos/precio-girasol"}
  ];

  const routesBR = [
    {pageTitle: "Sumário", pathName: "/cotacoes", hrefLang : "/granos", CepeaPIDs: "", CepeaTID: ""},
    {pageTitle: "Soja", pathName: "/cotacoes/preco-soja", hrefLang : "/granos/precio-soja", productNameES: "Soja", CepeaPIDs: "16,17", CepeaTID: "1"},
    {pageTitle: "Café", pathName: "/cotacoes/preco-cafe", CepeaPIDs: "7,8", CepeaTID: "1"},
    {pageTitle: "Trigo",  pathName: "/cotacoes/preco-trigo", hrefLang : "/granos/precio-trigo", productNameES: "Trigo", CepeaPIDs: "564,565", CepeaTID: "1"},
    {pageTitle: "Milho",  pathName: "/cotacoes/preco-milho", hrefLang : "/granos/precio-maiz", productNameES: "Maíz", CepeaPIDs: "13", CepeaTID: "1"},
    {pageTitle: "Algodão", pathName: "/cotacoes/preco-algodao", CepeaPIDs: "32", CepeaTID: "12"},
    {pageTitle: "Laranja", pathName: "/cotacoes/preco-laranja", CepeaPIDs: "673", CepeaTID: "2"},
    {pageTitle: "Boi Gordo", pathName: "/cotacoes/preco-boi-gordo", CepeaPIDs: "4", CepeaTID: "1"},
    {pageTitle: "Frango", pathName: "/cotacoes/preco-frango", CepeaPIDs: "446", CepeaTID: "1"},
    {pageTitle: "Suino", pathName: "/cotacoes/preco-suino", CepeaPIDs: "314,315,316,317,318", CepeaTID: "1"}
  ];

  const routes = isBrasil ? routesBR : routesAR;

  const pathData = routes.filter(route => route.pathName === pathName)[0];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;
  const dfpPrefix = isBrasil ? 'cotacoes' : 'granos';

  const templateBanners = [
    {
      adUnit: `${dfpPrefix}_middle_1`,
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_middle_2`,
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_middle_3`,
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_filmstrip`,
      sizes: [[300, 600]]
    }
  ];
  
  const defineFetch = () => {
    if (pathName === "/granos" || pathName === "/cotacoes") {
      fetchResumeData();
    } else {
      fetchGranosData();
      (isBrasil && fetchCepeaData());
    }
  };

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoadingNews(true);

    const newsCategoryID = isBrasil ? '32387' : '31';

    return getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=${newsCategoryID}&page=${currentPage - 1}&limit=15&canonical=${pathName}` })
      .then(result => {
        setUpdatedData(result.data);
        setIsLoadingNews(false)
      });
  };

  const fetchResumeData = () => {
    const apiUrl = process.env.RAZZLE_ENDPOINT_COTZ_API || window.env.RAZZLE_ENDPOINT_COTZ_API;
    const endpoint = isBrasil ? `${apiUrl}/api/Prices/GetBrasilPrices` : `${apiUrl}/api/Prices/GetGrainsData`;
    
    setIsLoadingResume(true);

    return getData({ url: endpoint})
      .then(result => {
        setResumeTableData(result.data);
        setIsLoadingGrain(false);
      });
  };

  const fetchGranosData = () => fetchGrainData().then(data => setTableData(data));

  const fetchCepeaData = () => {
    const url = process.env.RAZZLE_ENDPOINT_COTZ_API || window.env.RAZZLE_ENDPOINT_COTZ_API;
    const productIDs = pathData.CepeaPIDs;
    const typeID = pathData.CepeaTID;

    return getData({ url: `${url}/api/Cepea/GetCepeaPrices?productIDs=${productIDs}&typeIDs=${typeID}` })
      .then(result => {
        setCepeaData(result.data);
      });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);
  
  useEffect(() => {
    defineFetch();

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  // Refresh page after 10 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoadingNews(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (window) {
      manageGTMPageName('Home Granos');
    }
  }, []);


  const buildLinkTargets = () => {
    const linkTargets = [];

    routes.map(x => linkTargets.push(
      {pathname: x.pathName, title: x.pageTitle}
    ));

    return linkTargets;
  };

  const grainsList = linkTargets => {
    return (
      <>
        {linkTargets && linkTargets.map(route => 
          <li className={route.pathname === pathName ? 'active' : ''}><Link to={route}>{route.title}</Link></li>
        )}
      </>
    )
  };

  const getHrefLangPath = () => {
    return pathData.hrefLang;
  };

  const getHrefLang = inv => {
    return (inv) ? 'pt-br' : 'es-ar';
  };

  const getSelectedWeath = () => {
    return isBrasil 
      ? tableData.filter(x => x.Nombre === pathData.productNameES)[0]
      : tableData.filter(x => x.Nombre === pathData.pageTitle)[0]
  };

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        {getHrefLangPath() && <link rel="alternate" hrefLang={getHrefLang(true)} href={`${selfUrl}${getHrefLangPath()}`} />}
        {getHrefLangPath() && <link rel="alternate" hrefLang={getHrefLang(false)} href={`${serverData && serverData.canonical}`} />}
        <meta name="description" content={serverData && serverData.metaDescription} />
        <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <div className="granos container px-0">
        <PushBanner dfpPrefix={dfpPrefix} />
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0 mb-15">
              <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
            </section>
            <section className="container">
              <h1>{serverData && serverData.seoTitle}</h1>
              <p>{serverData && serverData.seoText}</p>
              <section className="pages-togler h-scroll">
                <ul className="granos-list">
                  {grainsList(buildLinkTargets())}
                </ul>
              </section>
              {resumeTableData ? <GranosTableResume prices={resumeTableData} /> : (isLoadingResume && tableLoader)}
              {tableData ? <GranosTable selectedWheat={getSelectedWeath()} cepea={cepeaData}/> : (isLoadingGrain && tableLoader)}
              <SectionTemplate category='Granos' data={updatedData || serverData} sectionTitle={t('keep_reading')} bannersData={templateBanners} loading={isLoadingNews} />
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={`${dfpPrefix}_followme`} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
      </div>
      <AdhesionBanner adUnit={`${dfpPrefix}_adhesion`} sizes={[[320, 80]]} />
      <PopUpBanner adUnit={`${dfpPrefix}_fullscreen`} sizes={[[800, 600], [320, 480]]} />
    </DFPSlotsProvider>
  );
}

Granos.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';
  const newsCategoryID = isBrasil ? '32387' : '31';

  try {
    const {
      data
    } = await getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=${newsCategoryID}&page=0&limit=15&canonical=${pathName}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Granos);
