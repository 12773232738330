import React, { useState, useEffect, useMemo, useRef } from 'react';
import TagManager from 'react-gtm-module';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import NewsIframe from './atoms/NewsIframe/NewsIframe';
import './styles/app.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './i18n';
import { useTranslation } from 'react-i18next';
import AgrofyHeader from './atoms/AgrofyHeader/AgrofyHeader';
import {
  withRouter,
  Route,
  Redirect,
  Switch,
  useHistory,
} from 'react-router-dom';
import { Theme, AgrofyTheme } from 'agrofy-ui-components';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback/ErrorFallback';
import UserContext from './atoms/UserContext/UserContext';
import getData from './services/GetData';
import { DarkModeProvider } from './atoms/DarkModeContext/DarkModeContext';

const App = withRouter((props) => {
  const { i18n } = useTranslation();
  const appLang = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;

  const countryCode = (
    process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE
  )
    .trim()
    .replace('-', '');
  const routes = props && props.routes && props.routes[countryCode];
  const firstRender = useRef(true);

  const initialData = props && props.initialData;
  const [renderSite, setRenderSite] = useState(true);
  const [mkpUserData, setMkpUserData] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const apiMarket = process.env.RAZZLE_ENDPOINT_MARKET;
  const envName = process.env.ENV_NAME || window.env.ENV_NAME;
  const newsEnv = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;
  
  const providerValue = useMemo(() => ({ mkpUserData, setMkpUserData, userLoading }), [mkpUserData, setMkpUserData, userLoading]);
  
  typeof window !== 'undefined' && window.sessionStorage.setItem("is_reloaded", true);

  let history = useHistory();

  const trackerPageView = (location) => {
    return setTimeout(() => {
      //const head = ReactHelmet.peek();
      const tagManagerArgs = {
        dataLayer: {
          event: 'trackPageview',
          pathName: location.pathname,
          // pageTitle: head.title.toString()
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }, 1000);
  };

  useEffect(() => {
    !firstRender.current && trackerPageView(history.location);
    firstRender.current = false;

  }, [history.location.pathname]);

  useEffect(() => {
    typeof window !== 'undefined' && 
    window.self !== window.top && 
    (envName && !(envName.includes("develop") || envName.includes("integration"))) && 
    setRenderSite(false);
  }, [])

  useEffect(() => {
    i18n.changeLanguage(appLang);
  }, []);

  useEffect(() => {
    const apiEndpointPrefix =
      process.env.AGROFYAPI_ENDPOINT_SERVICES_PREFIX ||
      window.env.AGROFYAPI_ENDPOINT_SERVICES_PREFIX;

    const getUserData = () => {
      getData({ url: `${apiEndpointPrefix}/user`, noCache: true })
        .then((resp) => {
          if (resp.data) {
            setMkpUserData(resp.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUserLoading(false);
        });
    };

    getUserData();
  }, []);

  const redirect = () => {
    typeof window !== 'undefined' &&
      window.open(
        `${apiMarket}/auth?urlRedirect=${newsEnv}foro/&bu=NEWS`,
        '_self'
      );
  };

  return (
    routes && (
      <>
        <Theme theme={AgrofyTheme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UserContext.Provider value={providerValue}>
              <DarkModeProvider>
                {renderSite ? (
                  <Switch>
                    <Redirect
                      from="/:url*(/+)"
                      to={history.location.pathname.slice(0, -1)}
                    />
                    {routes.map((route, index) => (
                      // pass in the initialData from the server or window.DATA for this
                      // specific route
                      <Route
                        key={index}
                        path={route.path}
                        exact
                        render={(props) => {
                          return (
                            <>
                              {route.showHeader && <AgrofyHeader />}
                              {route.showHeader && (
                                <Header hideTopics={route.hideTopics} />
                              )}
                              <div className="page-container pb-0">
                                {React.createElement(route.component, {
                                  ...props,
                                  initialData: initialData[index] || null,
                                })}
                              </div>

                              {route.showHeader && <Footer />}
                            </>
                          );
                        }}
                      />
                    ))}
                  </Switch>
                ) : (
                  <NewsIframe />
                )}
              </DarkModeProvider>
            </UserContext.Provider>
          </ErrorBoundary>
        </Theme>
      </>
    )
  );
});

export default App;
