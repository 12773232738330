export const getBreadcrumbData = (jsonData, isNav, opts) => {
  if (jsonData) {
    const data = JSON.parse(jsonData);

    const baseUrl = process.env.RAZZLE_ENDPOINT_SELF || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_SELF;
    const rootPage = isNav ?  '/' : baseUrl;
    
    const root = [{ level: 'Agrofy News', urlLevel: rootPage }];

    const key = isNav ? 'title' : 'level';
    const value = isNav ? 'link' : 'urlLevel';

    if (data) {
      var breadcrumbJsonArray = data.map((item) => {
        var rawKey = Object.keys(item)[0];
        var rawVal = Object.values(item)[0];
        var realVal = isNav ? rawVal : rawVal.replace(/^\/|\/$/g, '').replace(/\s+/g, '+');

        if (opts) {
          rawKey = rawKey.replace(opts.replace, opts.replacement);
          realVal = realVal.replace(opts.replace, opts.replacement);
        }

        var arr =  { 
          [key]: rawKey,
          [value]: isNav ? realVal.toLowerCase() : baseUrl + '/' + realVal.toLowerCase()
        }
        
        return arr;
      });
    
      return isNav ? breadcrumbJsonArray : root.concat(breadcrumbJsonArray);
    }
  }
};

export const buildBreadcrumbJson = (arr) => {
  let self = process.env.RAZZLE_ENDPOINT_SELF || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_SELF;
  var result = [];
  result[0] = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.agrofy.com.ar",
    "sameAs": ["https://news.agrofy.com.ar", "https://news.agrofy.com.br", "https://www.agrofy.com.br"],
    "logo": "https://news.agrofy.com.ar/agrofy.jpg",
    "name": "Agrofy",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Madres de plaza 25 de Mayo 3020",
      "addressLocality": "Rosario",
      "addressCountry": "AR",
      "addressRegion": "Santa Fe",
      "postalCode": "2000"
    }
  };
  
  if (arr) {
    result[1] = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumb List",
      "url": `${self}`,
      "itemListElement": arr.length > 0 ? itemLevels(arr) : []
    };       
  }

  return JSON.stringify(result, null, 4);
}

function itemLevels(arry) {
  if (arry) {
    const arr = arry.filter(n => n);
    const outArray = [];
    
    for (var i = 0; i < arr.length; i++) {
      const newItem = {
        '@type': 'ListItem',
        position: i + 1,
        name: arr[i].level,
        item: arr[i].urlLevel
      };

      const item = !arr[i].level ? null : newItem
      outArray.push(item)
    }

    return outArray.filter(n => n != null);
  }
}

export const getOrganizationJson = () => {
  const result = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.agrofy.com.ar",
    "sameAs": ["https://news.agrofy.com.ar", "https://news.agrofy.com.br", "https://www.agrofy.com.br"],
    "logo": "https://news.agrofy.com.ar/agrofy.jpg",
    "name": "Agrofy",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Madres de plaza 25 de Mayo 3020",
      "addressLocality": "Rosario",
      "addressCountry": "AR",
      "addressRegion": "Santa Fe",
      "postalCode": "2000"
    }
  };

  return JSON.stringify(result, null, 4);
}