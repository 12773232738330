import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './TagsRow.scss';
import { AgroTag, IchatRight } from 'agrofy-ui-components';
import { trackEventWithItemID } from '../../utils/GTMDataLayerFuncs';

// eslint-disable-next-line no-confusing-arrow
export const openNewTab = item => item.new_tab > 0 ? '_blank' : '_self';

function TagsRow({data}) {
  const { t } = useTranslation();
  const tagsAmount = data && data.length;

  const manageGTM = (title) => {
    trackEventWithItemID(
      'select_content',
      'click_home_tags',
      title
    );
  };

  const renderTag = (item, inx, amount) => {
    return item.external > 0 ? 
      <AgroTag
        key={item.id} // Move key here
        variant='primary'
        className="momentTopicsTag"
        onClick={() => manageGTM(item.title)}>
          <a href={`/${item.url}`} target={openNewTab(item)} rel="noopener noreferrer">
            {item.title}
          </a>
      </AgroTag> 
      : 
      <AgroTag
        key={item.id} // Move key here
        variant='primary'
        className="momentTopicsTag"
        onClick={() => manageGTM(item.title)}>
        <Link to={`/${item.url}`} target={openNewTab(item)}>
          {item.title}
        </Link>
      </AgroTag>;
  };
  
  const tagsList = data && data.map((item, inx) =>
    renderTag(item, inx, tagsAmount)
  );

  return (
    <>
      {data ?
        <div className="tags d-flex">          
          <label><IchatRight className="mr-10" height='24px' width='24px' fill='primary' />{t('hot_topics')}:</label>
          <ul className="h-scroll d-flex">
            {tagsList}
          </ul>
        </div>
      : null }
    </>
  );
}

TagsRow.defaultProps = {
  data: [
    {
      id: 182719,
      fecha_ini: '2019-08-29T15:00:00',
      fecha_fin: '2019-10-31T15:00:00',
      title: 'Agroactiva 2019',
      url: 'tags/agroactiva-2019'
    },
    {
      id: 182720,
      fecha_ini: '2019-08-29T15:00:00',
      fecha_fin: '2019-10-31T15:00:00',
      title: 'Expoagro',
      url: 'tags/expoagro'
    }
  ]
};

export default TagsRow;

