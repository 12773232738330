import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { useTranslation } from 'react-i18next';
import NewsletterCards from '../../molecules/NewsletterCards/NewsletterCards';
import getData from '../../services/GetData';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import { AgroButton } from 'agrofy-ui-components';
import putData from '../../services/PutData';
import postData from '../../services/PostData';
import deleteData from '../../services/DeleteData'; 
import Modal from 'react-bootstrap/Modal';
import TagManager from 'react-gtm-module';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../atoms/UserContext/UserContext';
import './NewsletterPage.scss';
import PushBanner from '../../atoms/PushBanner/PushBanner';
import NewslettersBR from "../../atoms/NewslettersBR/NewslettersBR";

const NewsletterPage = props => {
  const { t } = useTranslation();
  const queryValueFromUrl = props && props.location && props.location.search;
  const emailValueFromUrl = queryValueFromUrl ? queryValueFromUrl.replace('?email=','').split('&')[0] : null;
  const [email, setEmail] = useState(emailValueFromUrl || '');
  const [data, setData] = useState(false);
  const [newData, setNewData] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalBody, setModalBody] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const breadcrumb = [
    {
      title: 'Newsletter',
      link: '/newsletter'
    }
  ];

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };

    if (emailValueFromUrl) {
      getNewsletter()
    }
  }, []);

  useEffect(() => {
    if(window) {
      manageGTMPageName('Home Newsletter');
    }
  }, []);

  const manageGTM = label => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Users',
        eventAction: 'Agrofy News-Newsletter',
        eventLabel: label
      }
    };
    
    TagManager.dataLayer(tagManagerArgs);
  };

  const getNewsletter = () => {
    props.history.push(`/newsletter?email=${email || emailValueFromUrl}`)

    setLoadingEmail(true);

    const url = process.env.RAZZLE_ENDPOINT_FORO_API || window.env.RAZZLE_ENDPOINT_FORO_API;

    getData({ url: `${url}/api/Newsletters/GetSingleUser?&email=${email}` })
      .then(result => {
        setShowOptions(true);
        const status = result.data && result.data !== "";
        setData(result && result.data && result.data.Interests ? result.data.Interests : null);
        setHasData(status);
        setShowClose(true);
        setLoadingEmail(false);
        manageGTM('Search Subscription');
      });
  };

  const updateEmail = e => {
    setEmail(e.target.value);
  };

  const validEmail = () => {
    /* Validate email */
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkedOptions = () => {
    const query = newData && 
      newData.map(item => item).map(z => z && z.checked);

    return Array.isArray(query) && query.some(x => x);
  }

  const emptyData = () => {
    props.history.push('/newsletter')
    setShowClose(false);
    setShowOptions(false);
    setEmail('');
  };

  const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  )

  const subscribe = () => {
    setLoadingSave(true);

    const url = process.env.RAZZLE_ENDPOINT_FORO_API || window.env.RAZZLE_ENDPOINT_FORO_API;

    const params = newData.map(item => {
      const params = {
        email: email,
        ...(item.request === 'dailyResume' && {dailyResume: item.checked}),
        ...(item.request === 'todayNews' && {todayNews: item.checked}),
        ...(item.request === 'marketClosure' && {marketClosure: item.checked}),
        ...(item.request === 'boardPrices' && {boardPrices: item.checked}),
        ...(item.request === 'productPresentation' && {productPresentation: item.checked}),
        clientID: getCookieValue("_ga")
      }

      return params;
    });

    const query2 = params.reduce(function(result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
            result[key] = currentObject[key];
        }
      }

      return result;
    }, {});

    setModalTitle(<h5 className="text-green mate mt-20">{t('subscribe_newsletter_modal_title')}</h5>);

    setModalBody(<p className="mb-20">{t('subscribe_newsletter_modal_subtitle')}</p>);

    postData({ url: `${url}/api/Newsletters/InsertOrUpdateUser`, params: query2 }).then((res) => {
      handleShow();
      emptyData();
      setLoadingSave(false);

      if (query2.dailyResume || query2.todayNews || query2.marketClosure || query2.boardPrices || query2.productPresentation) {
        manageGTM('Subscription');
      }

      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }

    });
  };

  const unsubscribe = () => {
    setUnsubscribing(true);

    const url = process.env.RAZZLE_ENDPOINT_FORO_API || window.env.RAZZLE_ENDPOINT_FORO_API;

    setModalTitle(<h5 className="mt-20">{t('changes_saved')}</h5>);
    setModalBody(<p className="mb-20">{t('unsubscribe_newsletter_modal_subtitle')}</p>); 

    postData({ url: `${url}/api/Newsletters/UnsubscribeUser`, params: {
      email: email
    } }).then(() => {
      setUnsubscribing(false);
      emptyData();
      handleShow();

      manageGTM('Unsuscribe');

      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }

    });
  };

  const showModal = () => {
    return <Modal
      centered
      scrollable={true}
      show={show}
      onHide={handleClose} >
      <Modal.Header closeButton>
        {modalTitle}
      </Modal.Header>
      <Modal.Body>
        <div className="mt-20 mb-20">
          {modalBody}
          <a href="/">
            <AgroButton onClick={() => manageGTM()} size="lg" className="nwslOpt">
              Seguir navegando
            </AgroButton>
          </a>
        </div>
      </Modal.Body>
    </Modal>;
  };

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
      <div className="container newsletter-page px-0">
        <Helmet>
          <title>Agrofy News</title>
          <meta name="robots" content="index,follow" />
        </Helmet>
        <AgroPushNotification location="Section" />
        <PushBanner dfpPrefix="home" />
        <div className="newsletter-page mt-20" >
          <section className="breadcrumb-wrapper p-0 mb-10">
            <Breadcrumb breadcrumbLinks={breadcrumb} />
          </section>
          <section className="newsletter-page__container col-12" style={{ minHeight: '90vh' }}>
            <div className="col-lg-9 p-0">
            { !isBrasil ? (<>
              <h3>{t('newsletter_title')}</h3>
              <label className="mb-0">{t('newsletter_subtitle_1')}</label>
              <label className="mb-20">{t('newsletter_subtitle_2')}</label>            
              <div className="newsletter-page__container__email mb-20 d-flex">
                <div className="newsletter-page__container__email__input col-md-6 pl-0">
                  <input
                    className="form-control mr-10"
                    style={{ height: 'auto' }}
                    type="text"
                    name="email-input"
                    value={email}
                    onChange={e => updateEmail(e)}
                    placeholder="E-mail"
                    disabled={showClose}
                    autoComplete="off" />
                  {showClose ?
                    <button className="newsletter-page__container__email__input__close" onClick={emptyData}>
                      <i className="icon-close"></i>
                    </button> : null
                  }
                </div>
                {!showClose ?
                  <AgroButton size="md" className="nwslOpt subNewslettersHeader" onClick={() => getNewsletter()} disabled={!validEmail()} loading={loadingEmail}>
                    Buscar
                </AgroButton>
                  : null
                }
              </div>
              </>)
            : (<NewslettersBR />)}          
              { showOptions ?
                  <div className="newsletter-page__container__options">
                    <p className="bold">{t('newsletter_subtitle_3')}</p>
                    <NewsletterCards data={data} parentCallback={items => setNewData(items)} />
                    <div className="d-sm-flex justify-content-end newsletter-page__container__email pb-20 text-center text-sm-left">
                      <AgroButton size="md" className="nwslOpt subNewslettersHeader" onClick={subscribe} disabled={!validEmail() || !checkedOptions()} loading={loadingSave}>
                        Guardar preferencias
                      </AgroButton>
                      <AgroButton size="md" className="link-primary ml-10 nwslOpt subNewslettersHeader" onClick={unsubscribe} disabled={!hasData} loading={unsubscribing}>
                        Quiero desuscribirme
                      </AgroButton>
                    </div>
                  </div>
                  : null
              }
              {show ? showModal() : null}
            </div>
            <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped d-none d-lg-block">
              <div className="make-me-sticky">
                <AdSlot adUnit={'home_followme'} sizes={[[260, 600]]} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </DFPSlotsProvider>
  );
};

export default NewsletterPage;
