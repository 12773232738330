import React, { useState, useEffect, useContext } from "react";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import PopUpBanner from "../../atoms/PopUpBanner/PopUpBanner";
import PushBanner from '../../atoms/PushBanner/PushBanner';
import getData from "../../services/GetData";
import PaginationAgrofy from "../../atoms/PaginationAgrofy/PaginationAgrofy";
import SectionTemplate from "../../templates/sectionTemplate/SectionTemplate";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";
import withSSR from "../../withSSR";
import {
  gtmInitialize,
  manageGTMPageName,
  manageGTMUserStatus
} from "../../utils/GTMDataLayerFuncs";
import UserContext from "../../atoms/UserContext/UserContext";

const EspecialSoja = (props) => {
  const { t } = useTranslation();
  const serverData = props && props.serverData ? props.serverData : false;
  const [updatedData, setUpdatedData] = useState();
  const [seoTitle, setSeoTitle] = useState();
  const [seoText, setSeoText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const tagName =
    (serverData &&
      serverData.title_section &&
      serverData.title_section.replace(/^\w/, (c) => c.toUpperCase())) ||
    "";
  const amount = serverData && serverData.pages;

  const selfUrl = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;

  const dfpPrefix = "agrofynews_especificos/especialsoja";
  const dfpNetworkId1 = "21735159925";

  const { mkpUserData, userLoading } = useContext(UserContext);

  const templateBanners = [
    {
      adUnit: "especialsoja_middle_1",
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "especialsoja_middle_2",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "especialsoja_middle_3",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "especialsoja_filmstrip",
      sizes: [[300, 600]],
    },
  ];

  const fetchData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_NOTI_API ||
      window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true);
    return getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/especial-soja-2024&page=${
        currentPage - 1
      }&limit=15`,
    }).then((result) => {
      setUpdatedData(result.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById("section-template");
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById("section-template").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName(`Home Especial Soja`);
    }
  }, [serverData]);

  return (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
      <div className="container px-0">
        <Helmet>
          <title>{`Especial Soja | Encontrá todo sobre Soja - Agrofy News`}</title>
          <meta property="og:title" content={`Especial Soja | Agrofy News`} />
          <meta property="og:url" content={`https://news.agrofy.com.ar/especial-soja`} />
          {/*<meta property="og:image" content={socialImg} />*/}
          <meta property="og:description" content={ `Especial Soja | Encontrá todo sobre Soja - Agrofy News`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@AgrofyNews" />
          <meta name="twitter:title" content={`Especial Soja | Agrofy News`}/>
          <meta name="twitter:description" content={`Conocé todo acerca del Mercado de Soja`} />
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href={`https://news.agrofy.com.ar/especial-soja`}/>
          <meta name="description" content={`Conocé todo acerca del Mercado de Soja: Manejo, agtech, precios, noticias y más! El mejor contenido lo encontrás en el Especial Soja de Agrofy News`} />
        </Helmet>
        <AgroPushNotification location="Section" />
        <section className="mt-30 container">
        <picture>
            <source 
              srcSet="https://news.agrofystatic.com/especiales/portada_especialsoja_1170x230.jpg?d=1170x230"
              media="(min-width: 1000px)"
            />
            <source 
              srcSet="https://news.agrofystatic.com/especiales/portada_especialsoja_1170x230.jpg?d=1170x230"
              media="(min-width: 600px)"
            />
            <img 
              src="https://news.agrofystatic.com/especiales/portada_especialsoja_420x150.jpg?d=420x150" 
              alt="BASF logo"
              style={{maxWidth: '100%', maxHeight: '100%'}}
            />
          </picture>
        </section>
        <section className="mt-30 container section-subject">
          <p>
            Bienvenidos a la sección de Agrofy News especializada en el cultivo de soja, 
            donde encontrarás las últimas noticias, análisis de mercado, y avances tecnológicos 
            relacionados con esta industria.
          </p>
          <p className="hide-mobile">
            Nuestro objetivo es mantenerte informado sobre las tendencias en producción, 
            novedades en técnicas de siembra y cosecha, además de proporcionar informes detallados sobre el clima, 
            las regulaciones y el impacto económico de este cultivo clave en la agricultura global. Mantente al día con 
            información confiable y actualizada para optimizar tus decisiones en el campo.
          </p>
        </section>
        <PushBanner dfpPrefix={dfpPrefix} />
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="container">
              <SectionTemplate
                hasVideo={false}
                isExpo={true}
                data={updatedData || serverData}
                classes="small-spacing-top"
                bannersData={templateBanners}
                loading={isLoading}
                hasCarousel={false}
                carouselKeyword="Tienda agro"
                showNewslettersForm={false}
              />
              <div className="mb-36">
                {currentPage && amount && (
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                )}
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot adUnit={dfpPrefix + "_followme"} sizes={[[260, 600]]} />
            </div>
          </div>
        </section>
        <AdhesionBanner 
          adUnit={dfpPrefix + "_adhesion"}
          sizes={[[320, 80]]}
          specialNetworkID={dfpNetworkId1}          
        />
        <PopUpBanner
          adUnit={dfpPrefix + "_fullscreen"}
          sizes={[
            [800, 600],
            [320, 480],
          ]}
          specialNetworkID={dfpNetworkId1}
        />
      </div>
    </DFPSlotsProvider>
  );
};

EspecialSoja.getInitialProps = async ({ match, req, res }) => {
  const url =
    process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  try {
    const { data } = await getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/especial-soja-2024&page=0&limit=15`,
    });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(EspecialSoja);
