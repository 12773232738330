import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom'
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import PopUpBanner from "../../atoms/PopUpBanner/PopUpBanner";
import PushBanner from '../../atoms/PushBanner/PushBanner';
import Breadcrumb from "../../atoms/Breadcrumb/Breadcrumb";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import getData from "../../services/GetData";
import { Helmet } from "react-helmet-async";
import PaginationAgrofy from "../../atoms/PaginationAgrofy/PaginationAgrofy";
import SectionTemplate from "../../templates/sectionTemplate/SectionTemplate";
import { useTranslation } from "react-i18next";
import withSSR from "../../withSSR";
import { buildBreadcrumbJson } from "./../../utils/breadcrumbJson";
import { getBreadcrumbData } from "./../../utils/breadcrumbJson";
import { splitUrl, strToLowerCase } from "./../../utils/stringUtils";
import NotFound from "../NotFound/NotFound";
import {
  gtmInitialize,
  manageGTMPageName,
  manageGTMUserStatus
} from "./../../utils/GTMDataLayerFuncs";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";
import UserContext from "../../atoms/UserContext/UserContext";

const TagsPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const serverData =
    props && props.serverData && props.serverData.news.length > 0
      ? props.serverData
      : undefined;
  const pathName = props ? props.location.pathname : false;
  const [data, setData] = useState(serverData || null);
  const [seoTitle, setSeoTitle] = useState();
  const [seoText, setSeoText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const amount = serverData && serverData.pages;
  const [tagSlug, setTagSlug] = useState(
    props && props.match && props.match.params && props.match.params.tagName.split('?')[0]
  );
  const [loading, setLoading] = useState(false);
  const tagName =
    (serverData &&
      serverData.title_section &&
      serverData.title_section.replace(/^\w/, (c) => c.toUpperCase())) ||
    "";

  const selfUrl = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const dfpNetworkId1 = isBrasil ? "22043621183" : "21735159925";

  let bannerCategory = isBrasil ? "agrofy_news/tag" : "agrofynews_site/tag";
  switch (tagSlug) {
    case "syngenta":
      bannerCategory = "agrofynews_especificos/syngenta";
      break;
  }

  const breadcrumb = [
    {
      title: `${t("tags")}`,
      link: "",
    },
    {
      title: `${tagName ? tagName : ""}`,
      link: "",
    },
  ];

  const templateBanners = [
    {
      adUnit:  "tag_middle_1",
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: bannerCategory + "tag_middle_2",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: bannerCategory + "tag_middle_3",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: bannerCategory + "tag_filmstrip",
      sizes: [[300, 600]],
    },
  ];

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };
  }, []);

  useEffect(() => {
    setTagSlug(
      props && props.match && props.match.params && props.match.params.tagName.split('?')[0]
    );
    if (tagSlug) {
      const url =
        process.env.RAZZLE_ENDPOINT_NOTI_API ||
        window.env.RAZZLE_ENDPOINT_NOTI_API;
      const fetchData = () => {
        setLoading(true);
        getData({
          url: `${url}/api/News/GetNewsListByTag?tag=tags/${tagSlug}&page=${
            currentPage - 1
          }&limit=15`,
        }).then((result) => {
          setData(result && result.data);
          setSeoTitle(result && result.data && result.data.title_section);
          setSeoText(result && result.data && result.data.seoText);
          setLoading(false);
        });
      };
      fetchData();
    }
    const section = document.getElementById("section-template");
    if (section) {
      document.getElementById("section-template").scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName(`Tag ${serverData.title_section}`);
    }
  }, [serverData]);

  const breadcrumbArr = [
    { level: "Agrofy News", urlLevel: selfUrl },
    { level: "Tags", urlLevel: selfUrl + '/' + splitUrl(pathName, 1) },
    { level: `${tagName}`, urlLevel: selfUrl + '/' + `${pathName.replace(/^\/|\/$/g, "")}` }
  ];

  const selectedTags = [
    "dolar",
    "dolar-hoy",
    "dolar-banco-nacion",
    "dolar-divisa-banco-nacion",
    "cotizacion-dolar-argentina",
    "dolar-en-argentina",
    "cotizacion-dolar-blue",
    "cotizacion-dolar-oficial",
  ].map((str) => `/tags/${str}`);
  const showDolarTable = selectedTags.includes(pathName);

  const regex = (str) => str.replace(/<[^>]*>?/gm, " ");
  const seoTextDefault = `Te mostramos las principales noticias sobre ${tagName}. Informate con nosotros.`;
  const setMetaDescription = () =>
    props & props.serverData && props.serverData.seoText
      ? regex(props.serverData.seoText)
      : data && data.seoText
      ? regex(data.seoText)
      : seoTextDefault;
  const metaDescriptionBrasil = `Encontre as últimas notícias sobre ${tagName} na Agrofy News! Tudo o que você precisa saber sobre ${tagName} vai encontrar aqui.`;

  const metaTitle = isBrasil ? "Últimas noticias na Agrofy News" : "Últimas noticias en Agrofy News";

  return props.isInvalidID ||
    (typeof serverData === "undefined" && !loading) ? (
    <NotFound />
  ) : (
    <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
      <div className="page tags-page container px-0">
        <Helmet>
          <title>{`${tagName} | ${metaTitle}`}</title>
          <meta name="robots" content="index,follow" />
          <link
            rel="canonical"
            href={strToLowerCase(`${selfUrl}/tags/${tagSlug}`)}
          />
          <meta
            name="description"
            content={isBrasil ? metaDescriptionBrasil : setMetaDescription()}
          />
          <script type="application/ld+json">
            {buildBreadcrumbJson(breadcrumbArr)}
          </script>
        </Helmet>
        <AgroPushNotification location="Tags" />
        <PushBanner dfpPrefix={bannerCategory} />
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0">
              <Breadcrumb breadcrumbLinks={breadcrumb} />
            </section>
            <section className="tags-page-title container mt-15">
              <h1>{seoTitle}</h1>
              <div className="tags-page-div">
                <p>{seoText}</p>
              </div>
              <SectionTemplate
                data={data}
                classes="small-spacing-top"
                bannersData={templateBanners}
                isTagPage
                showDolarTable={showDolarTable}
                loading={loading}
              ></SectionTemplate>
              <div className="mb-36">
                {currentPage && amount && amount > 1 && (
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                )}
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <AdSlot
                adUnit={`${bannerCategory}_followme`}
                sizes={[[260, 600]]}
              />
            </div>
          </div>
        </section>
        <AdhesionBanner
          adUnit={`${bannerCategory}_adhesion`}
          sizes={[[320, 80]]}
        />
        <PopUpBanner
          adUnit={`${bannerCategory}_fullscreen`}
          sizes={[
            [800, 600],
            [320, 480],
          ]}
        />
      </div>
    </DFPSlotsProvider>
  );
};

TagsPage.getInitialProps = async ({ match, req, res }) => {
  const url =
    process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const tagName = match.params.tagName.split('?')[0];
  try {
    const { data } = await getData({
      url: `${url}/api/News/GetNewsListByTag?tag=tags/${tagName}&page=0&limit=15`,
    });
    return { serverData: data };
  } catch (e) {
    console.error(e);
    const isInvalidID = true;
    return { isInvalidID };
  }
};

export default withSSR(TagsPage);
